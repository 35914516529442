<template>
  <div v-if="!hasError">
    <slot />
  </div>
  <h1
    v-else
    class="error--text ma-4"
  >
    Что-то пошло не так.
  </h1>
</template>

<script>
export default {
  name: 'ErrorBoundary',

  data () {
    return {
      hasError: false
    }
  },

  watch: {
    $route: {
      handler: function () {
        this.hasError = false
      }
    }
  },

  errorCaptured () {
    this.hasError = true
  }
}
</script>
